import { useState } from 'react';

import { useParams } from 'next/navigation';

import { Globe } from '../../../icons/components';
import { Text } from '../text/text';
import {
  CloseLanguagePicker,
  ContentWrapper,
  MobileLanguagePicker,
  MobileLanguageSelect,
  OpenPickerButtonDesktop,
  OpenPickerButtonMobile,
  PickerButton,
  PopoverArrow,
  SelectedLanguage,
  StyledPopoverPortal,
} from './language-picker.style';
import * as Popover from '@radix-ui/react-popover';
import PeopleDoodleIcon from 'icons/components/PeopleDoodleIcon';
import SelectedTick from 'icons/components/SelectedTick';
import { RedirectsPage } from 'lib/generate-navigation';
import navData from 'lib/navigation-redirects.json';

const getCurrentNavPath = (slug: string[] | string, navData?: RedirectsPage[]) => {
  let url = '';
  if (Array.isArray(slug)) {
    url = slug?.join('/');
  } else {
    url = slug;
  }

  if (slug) {
    return navData?.find((nav: RedirectsPage) => nav?.en?.url?.includes?.(url) || nav?.is?.url?.includes?.(url));
  }
  return navData?.find(
    (nav: RedirectsPage) => nav?.en?.navigation_title === 'Frontpage' || nav?.is?.navigation_title === 'Frontpage'
  );
};

export default function LanguagePicker() {
  const { locale, slug } = useParams() as { slug: string[] | string; locale: string };

  const currentNavPath = getCurrentNavPath(slug, navData);

  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  return (
    <>
      <Popover.Root>
        <Popover.Trigger asChild>
          <OpenPickerButtonDesktop
            className="IconButton"
            aria-label="Update dimensions"
          >
            <SelectedLanguage>
              <Popover.Anchor>
                <Globe
                  height={25}
                  width={25}
                />
              </Popover.Anchor>

              <Text
                data-testid="selected-language"
                $variant="articleparag"
                $color="ui-textGray"
                $noBalancer
              >
                {locale === 'is' ? 'Íslenska' : 'English'}
              </Text>
            </SelectedLanguage>
          </OpenPickerButtonDesktop>
        </Popover.Trigger>
        <StyledPopoverPortal>
          <Popover.Content
            className="PopoverContent"
            style={{ zIndex: 9999 }}
            align="start"
          >
            <PopoverArrow height={10} />

            <ContentWrapper>
              <PickerButton
                href={currentNavPath?.is?.url ?? '/'}
                selected={locale === 'is'}
                data-testid="language-picker-option-is"
              >
                <SelectedTick
                  data-testid="selected-tick-picker-option-is"
                  height={5}
                />
                <Text>Íslenska</Text>
              </PickerButton>
              <PickerButton
                href={currentNavPath?.en?.url ?? '/'}
                selected={locale === 'en'}
                data-testid="language-picker-option-en"
              >
                <SelectedTick
                  data-testid="selected-tick-picker-option-en"
                  height={5}
                />
                <Text>English</Text>
              </PickerButton>
            </ContentWrapper>
          </Popover.Content>
        </StyledPopoverPortal>
      </Popover.Root>
      <MobileLanguagePicker>
        <OpenPickerButtonMobile onClick={toggleMenu}>
          <SelectedLanguage>
            <Globe
              height={25}
              width={25}
            />

            <Text
              $variant="h4"
              $color="primary-base100"
              $noBalancer
              data-testid="mobile-selected-language"
            >
              {locale === 'is' ? 'Íslenska' : 'English'}
            </Text>
          </SelectedLanguage>
        </OpenPickerButtonMobile>
        {mobileMenuOpen && (
          <MobileLanguageSelect>
            <CloseLanguagePicker
              height={15}
              onClick={toggleMenu}
            />
            <ContentWrapper>
              <PickerButton
                href={currentNavPath?.is?.url ?? '/'}
                selected={locale === 'is'}
                data-testid="mobile-language-picker-islandic-option"
              >
                <SelectedTick
                  data-testid="selected-tick-picker-option-is"
                  height={5}
                />
                <Text
                  $variant="h4"
                  $fontWeight={locale === 'is' ? 'Bold' : 'Light'}
                >
                  Íslenska
                </Text>
              </PickerButton>
              <PickerButton
                href={currentNavPath?.en?.url ?? '/'}
                selected={locale === 'en'}
                data-testid="mobile-language-picker-english-option"
              >
                <SelectedTick
                  data-testid="selected-tick-picker-option-en"
                  height={5}
                />
                <Text
                  $variant="h4"
                  $fontWeight={locale === 'en' ? 'Bold' : 'Light'}
                >
                  English
                </Text>
              </PickerButton>
            </ContentWrapper>{' '}
            <PeopleDoodleIcon />
          </MobileLanguageSelect>
        )}
      </MobileLanguagePicker>
    </>
  );
}
